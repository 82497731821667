<template>
  <div
    class="side-menu cart-menu"
    :class="{ 'cart-empty': cart.items.length === 0 }"
    data-cy-cart-menu
  >
    <div class="cart-menu__top d-flex align-center align-md-end">
      <div
        class="d-flex align-center align-md-end justify-space-between flex-grow-1"
      >
        <title-2 class="mb-0 d-flex align-center">
          <span>
            {{ $t('cart.title') }}
            <span v-if="cart.items.length > 0">({{ cart.items.length }})</span>
          </span>
          <icon name="cart-full" class="ml-1" />
        </title-2>

        <a
          href="#"
          class="link-cta"
          @click.prevent="$nuxt.$emit(EVENTS.CLOSE_CART)"
        >
          <span class="w-400 mr-0-4">{{ $t('action.close') }}</span>
          <icon name="cross" />
        </a>
      </div>
    </div>

    <div class="cart-menu__inner">
      <transition name="fade" mode="out-in">
        <p
          v-if="cart.items.length === 0"
          class="text-small pt-1-5 pt-md-2 mb-0-4"
        >
          {{ $t('cart.empty') }}
        </p>

        <div v-else>
          <transition-group name="fade-from-left" tag="div">
            <cart-item
              v-for="item in cart.items"
              :key="item.variantId"
              :item="item"
              :loading="item.lineId === loadingItem"
              @update-quantity="updateQuantity"
            />
          </transition-group>
        </div>
      </transition>
    </div>

    <transition name="fade-from-bottom">
      <cart-up-sell-block
        v-if="upsell.has && upsell.list.length > 0"
        ref="upselling"
      />
    </transition>

    <transition name="fade-from-bottom">
      <div
        v-if="showMultipass && multipassProduct && !multipassInCart"
        class="cart-free-delivery pl-2 pr-2 pb-1 pt-1"
        style="background-color: white"
      >
        <div class="d-flex align-center">
          <icon
            name="box"
            class="ml-0-8 mr-1-5"
            style="width: 30px; height: 30px"
          />
          <div class="flex-grow-1">
            <nuxt-link
              :href="multipassLink"
              :to="multipassLink"
              @click.native="closeCart"
            >
              <p class="text-small text-uppercase lh-1 mb-0-2">
                <strong class="w-600">
                  {{ $t('cart.multipass.title') }}
                </strong>
              </p>
            </nuxt-link>
            <p class="text-small text-color-secondary-2 mb-0">
              {{ $t('cart.multipass.explain') }}
            </p>

            <p class="d-flex align-baseline text-base text-secondary mb-0">
              <span class="mr-1">{{
                $t('cart.multipass.price', {
                  price: parseInt(
                    multipassProduct.priceRange.minVariantPrice.amount,
                    10
                  ),
                })
              }}</span>
              <span class="text-color-secondary-2">{{
                $t('cart.multipass.no_obligation')
              }}</span>
            </p>
          </div>

          <div class="pl-1">
            <primary-button
              :text="$t('cart.multipass.cta')"
              aspect="light"
              :loading="loadingMultipass"
              @click="addMultipassToCart"
            />
          </div>
        </div>
      </div>
      <div
        v-else-if="showFreeDelivery"
        class="cart-free-delivery pl-2 pr-2 pb-1 pt-1"
      >
        <div class="d-flex align-center mb-0-6">
          <icon name="box" class="mr-0-6" />
          <div v-if="freeShippingPriceLeft <= 0">
            <p class="text-extra-small text-uppercase lh-1 mb-0-2">
              <strong class="w-600">{{ $t('cart.delivery_free') }}</strong>
            </p>
            <p class="text-small text-color-secondary-2 mb-0">
              {{ $t('cart.delivery_free_sub') }}
            </p>
          </div>
          <div v-else>
            <p class="text-extra-small text-uppercase lh-1 mb-0-2">
              <strong class="w-600">
                {{ $t('cart.delivery', { price: FREE_SHIPPING_PRICE }) }}
              </strong>
            </p>
            <p
              class="text-small text-color-secondary-2 mb-0"
              v-html="
                $t('cart.free_delivery_left', { price: freeShippingPriceLeft })
              "
            />
          </div>
        </div>

        <progress-bar :value="cart.subTotalPrice" :total="200" />
      </div>
    </transition>

    <transition name="fade-from-bottom">
      <div v-if="cart.items.length > 0" class="cart-menu__total">
        <p class="h4 d-flex justify-space-between align-center mb-0-4">
          <span class="mr-1">{{ $t('cart.sub-total') }}</span>

          <span class="cart-item__price text-secondary w-700">
            {{ $n(cart.subTotalPrice, 'currency') }}
          </span>
        </p>

        <p class="text-color-secondary-2 text-small mb-1">
          {{ $t('cart.shipping-calculated-later') }}
        </p>

        <primary-button
          :text="$t('action.checkout')"
          align="center"
          with-arrow
          :loading="loadingCheckout"
          data-cy-checkout-btn
          @click="goToCheckout"
        />
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import Title2 from '@/components/Title/Title2'
import CartItem from '@/components/cart/CartItem'
import CartUpSellBlock from '@/components/cart/CartUpSellBlock'
import PrimaryButton from '@/components/buttons/Primary'
import Icon from '@/components/icons/Icon'
import ProgressBar from '@/components/progressbar/ProgressBar'

import AddToCartItem from '@/entities/cart/CartItem'

import services from '@/services'

import { EVENTS } from '@/const/events'
import ShopifyBase64 from '~/utils/shopifyBase64'

export default {
  name: 'CartMenu',
  components: {
    Title2,
    CartItem,
    CartUpSellBlock,
    PrimaryButton,
    Icon,
    ProgressBar,
  },
  data() {
    return {
      loadingItem: '',
      loadingCheckout: false,
      loadingMultipass: false,
      EVENTS,
      FREE_SHIPPING_PRICE: parseInt(process.env.FREE_SHIPPING_PRICE),
      multipassProduct: null,
    }
  },
  computed: {
    ...mapGetters({
      cart: 'cart/cart',
      upsell: 'cart/upsell',
      authenticated: 'auth/authenticated',
      customer: 'auth/customer',
    }),
    showFreeDelivery() {
      return this.cart.items.length > 0 && this.FREE_SHIPPING_PRICE > 0
    },
    freeShippingPriceLeft() {
      return this.FREE_SHIPPING_PRICE - this.cart.subTotalPrice
    },
    showMultipass() {
      return process.env.MULTIPASS_ID && this.$i18n.locale === 'fr-FR'
    },
    multipassPrismicUID() {
      if (this.showMultipass && this.multipassProduct) {
        const prismicUidMetafield = this.multipassProduct.metafields.find(
          (m) => m && m.namespace === 'prismic' && m.key === 'links'
        )

        if (prismicUidMetafield) {
          const uids = JSON.parse(prismicUidMetafield.value)

          if (uids[this.$route.params.context]) {
            return uids[this.$route.params.context].fr
          }
        }
      }

      return null
    },
    multipassLink() {
      if (this.multipassPrismicUID) {
        return this.$contextPrismicPath({
          uid: this.multipassPrismicUID,
          type: 'product',
        })
      }
      return null
    },
    multipassInCart() {
      if (
        this.showMultipass &&
        this.multipassProduct &&
        this.multipassProduct.variants.edges.length > 0
      ) {
        return this.cart.items.some(
          (i) => i.productId === ShopifyBase64.getId(this.multipassProduct.id)
        )
      }

      return false
    },
  },
  mounted() {
    if (this.showMultipass) {
      this.loadMultipass()
    }
  },
  methods: {
    ...mapActions({
      addItemToCart: 'cart/addItems',
      updateItem: 'cart/updateItem',
    }),
    async loadMultipass() {
      try {
        const p = await services.productService.getShopifyProduct(
          process.env.MULTIPASS_ID,
          this.$i18n.locale,
          true
        )
        if (p.data?.data?.node === null) {
          return
        }

        this.multipassProduct = p.data.data.node
      } catch (err) {
        this.$logError.captureException(err)
      }
    },
    updateUpsellingBlock() {
      if (this.$refs.upselling) {
        this.$refs.upselling.currentUpsellingItem = 1
      }
    },
    updateQuantity(cartItem, quantity) {
      this.loadingItem = cartItem.lineId
      this.updateItem({
        id: cartItem.lineId,
        variantId: cartItem.variantId,
        quantity,
      }).then((_) => {
        if (quantity === 0) {
          this.$track.removeFromCart(cartItem, this.cart.currency, this.cart)
          this.updateUpsellingBlock()
        }

        this.loadingItem = ''
      })
    },
    addMultipassToCart() {
      try {
        if (this.multipassProduct.variants.edges.length > 0) {
          this.loadingMultipass = true

          const customAttributes = []
          if (this.multipassPrismicUID) {
            customAttributes.push({
              key: '_slug',
              value: this.multipassPrismicUID,
            })
          }
          customAttributes.push({ key: '_locale', value: this.$i18n.locale })
          customAttributes.push({
            key: '_basket_date',
            value: new Date().toJSON(),
          })

          const cartItem = new AddToCartItem({
            variant: {
              id: this.multipassProduct.variants.edges[0].node.id,
            },
            quantity: 1,
            customAttributes,
          })
          this.addItemToCart([cartItem]).finally(() => {
            this.loadingMultipass = false
          })
        }
      } catch (err) {
        this.$logError.captureException(err)
      }
    },
    async goToCheckout() {
      this.loadingCheckout = true
      const checkoutUrl = new URL(this.cart.webUrl)
      checkoutUrl.searchParams.set('locale', this.$i18n.locale.substring(0, 2))

      if (this.$route.params.context) {
        checkoutUrl.searchParams.set('context', this.$route.params.context)
      }

      const returnUrl = checkoutUrl.toString()

      this.$track.beginCheckout(this.cart)

      if (this.authenticated && this.customer.email) {
        try {
          const multipassURL =
            await services.multipassLoginService.buildMultipassLoginUrl(
              this.customer.email,
              returnUrl
            )
          window.location = multipassURL
          return
        } catch (err) {}
      }

      window.location = returnUrl
    },
    closeCart() {
      this.$nuxt.$emit(EVENTS.CLOSE_CART)
      return true
    },
  },
}
</script>

<style lang="scss">
.cart-menu {
  $cart-width: 100vw;

  width: $cart-width;
  right: -$cart-width;
  background: var(--tertiary-color);
  transition: all 0.4s cubic-bezier(0.46, 0.01, 0.32, 1);
  display: flex;
  flex-direction: column;
  max-height: 100lvh;

  @include mq($from: tablet) {
    $cart-width: 100% * 4 / $grid-columns;

    width: $cart-width;
    right: -$cart-width;
  }

  &.side-menu--open {
    right: 0;
    transition: all 0.4s cubic-bezier(0.46, 0.01, 0.32, 1);
    max-width: none;
  }

  &__top {
    border-bottom: 1px solid var(--secondary-color-3);

    > div {
      padding: 0 rem($spacing);
      min-height: var(--header-height);

      @include mq($from: tablet) {
        padding: 0 rem($spacing * 1.5) rem($spacing * 1.5);
      }
    }

    .link-cta {
      font-size: rem(15px);
      text-transform: none;

      > span {
        margin-top: rem(-1px);
      }
    }

    h2 {
      font-size: rem($h6-fs);

      .icon {
        position: relative;
        top: rem(-1px);
        width: rem(20px);
        height: rem(20px);
      }
    }
  }

  &__inner {
    padding: 0 var(--spacing) var(--spacing);
    flex-grow: 1;
    overflow: auto;
    min-height: rem(110px);

    @include mq($from: tablet) {
      padding: 0 calc(var(--spacing) * 1.5) calc(var(--spacing) * 1.5);
      min-height: rem(130px);
    }

    .cart-empty & {
      flex-grow: 0;
      min-height: rem(80px);
    }
  }

  &__total {
    padding: calc(var(--spacing) * 1.5) var(--spacing);
    border-top: 1px solid var(--secondary-color-3);

    @include mq($until: tablet) {
      background: white;
    }

    @include mq($from: tablet) {
      padding: calc(var(--spacing) * 1.5);
    }

    .action {
      font-size: var(--base-font-size);
      padding-left: var(--spacing);
      padding-right: var(--spacing);
    }
  }

  .progress-bar {
    width: 100%;
    height: rem(4px);
    border-radius: 1px;
    background: var(--secondary-color-5);

    &__value {
      background: var(--yellow-color);
      border-radius: 1px;
      transition: all 0.3s;
    }
  }
}
</style>
