<template>
  <div id="navMenu" class="side-menu nav-mobile">
    <div class="nav-mobile__top nav-mobile__to-animate">
      <i18n-switcher
        v-if="$route.params.context && $route.params.context !== 'f'"
        class="text-small"
      />
      <primary-button
        :text="$t('action.close')"
        aspect="transparent"
        size="tertiary"
        with-arrow
        arrow-position="left"
        arrow-is-icon
        icon="cross"
        @click.prevent="$emit('close', $event)"
      />
    </div>

    <div
      v-if="$i18n.locale === 'fr-FR'"
      class="nav-mobile__context d-flex nav-mobile__to-animate"
    >
      <a
        :href="localePath('/h')"
        class="nav-mobile__link--context"
        :class="{ 'link-active': currentContext === 'h' }"
        @click="$track.click('switch_context', { context: 'homme' })"
      >
        <span>{{ $t('context.h') }}</span>
      </a>
      <a
        :href="localePath('/f')"
        class="nav-mobile__link--context"
        :class="{ 'link-active': currentContext === 'f' }"
        @click="$track.click('switch_context', { context: 'femme' })"
      >
        <span>{{ $t('context.f') }}</span>
      </a>
    </div>

    <hr class="nav-mobile__to-animate" />

    <nav :key="`nav-mobile-${renderTime}`" class="nav-mobile__inner">
      <div v-if="eshopMenuVersion" class="nav-mobile__eshop-links">
        <component
          :is="linkComponent(link)"
          v-for="link in eshopMenuVersion"
          :key="link.text"
          class="nav-mobile__link d-block text-uppercase text-secondary nav-mobile__to-animate"
          :class="{
            'nav-mobile__link--highlight': link.highlight,
            'nav-mobile__link--with-submenu': hasSubmenu(link),
          }"
          :style="[
            link.highlight_color
              ? { '--link-highlight-color': link.highlight_color }
              : {},
            link.highlight_background
              ? { '--link-highlight-background': link.highlight_background }
              : {},
          ]"
          v-bind="linkAttributes(link)"
          @click.native="$emit('close', $event)"
        >
          <span v-if="link.text" class="d-flex align-center"
            >{{ link.text }}
            <b v-if="link.countdown" class="link__countdown pl-0-4">{{
              getCountdown(link)
            }}</b>
          </span>

          <div v-if="hasSubmenu(link)" class="pt-1">
            <nuxt-link
              v-for="subLink in link.sub_menu.data.links"
              :key="subLink.text"
              :to="$contextPrismicPath(subLink.link)"
              class="nav-mobile__link d-block text-uppercase text-secondary nav-mobile__to-animate"
              :class="{ 'nav-mobile__link--highlight': subLink.highlight }"
              :style="[
                subLink.highlight_color
                  ? { '--link-highlight-color': subLink.highlight_color }
                  : {},
                subLink.highlight_background
                  ? {
                      '--link-highlight-background':
                        subLink.highlight_background,
                    }
                  : {},
              ]"
              @click.native="$emit('close', $event)"
            >
              <span v-if="subLink.text" class="d-flex align-center"
                >{{ subLink.text }}
              </span>
            </nuxt-link>
          </div>
        </component>
        <hr class="nav-mobile__to-animate mt-1" />
      </div>
      <div>
        <component
          :is="linkComponent(link)"
          v-for="link in menuVersion"
          :key="link.text"
          class="nav-mobile__link d-block text-uppercase text-secondary nav-mobile__to-animate"
          :class="{ 'nav-mobile__link--highlight': link.highlight }"
          :style="[
            link.highlight_color
              ? { '--link-highlight-color': link.highlight_color }
              : {},
            link.highlight_background
              ? { '--link-highlight-background': link.highlight_background }
              : {},
          ]"
          v-bind="linkAttributes(link)"
          @click.native="$emit('close', $event)"
        >
          <span v-if="link.text" class="d-flex align-center"
            >{{ link.text }}
            <b v-if="link.countdown" class="link__countdown pl-0-4">{{
              getCountdown(link)
            }}</b></span
          >

          <div v-if="hasSubmenu(link)" class="pt-1">
            <nuxt-link
              v-for="subLink in link.sub_menu.data.links"
              :key="subLink.text"
              :to="$contextPrismicPath(subLink.link)"
              class="nav-mobile__link d-block text-uppercase text-secondary nav-mobile__to-animate"
              :class="{ 'nav-mobile__link--highlight': subLink.highlight }"
              :style="[
                subLink.highlight_color
                  ? { '--link-highlight-color': subLink.highlight_color }
                  : {},
                subLink.highlight_background
                  ? {
                      '--link-highlight-background':
                        subLink.highlight_background,
                    }
                  : {},
              ]"
              @click.native="$emit('close', $event)"
            >
              <span v-if="subLink.text" class="d-flex align-center"
                >{{ subLink.text }}
              </span>
            </nuxt-link>
          </div>
        </component>
      </div>
    </nav>

    <hr class="nav-mobile__to-animate" />

    <div class="nav-mobile__bottom">
      <nuxt-link
        :to="$contextPath('/account')"
        class="nav-mobile__link d-block text-uppercase text-secondary nav-mobile__to-animate"
        @click.native="$emit('close', $event)"
      >
        {{ $t(authenticated ? 'link.account' : 'link.sign-in') }}
      </nuxt-link>

      <nuxt-link
        v-if="authenticated"
        :to="$contextPath('/account/orders')"
        class="nav-mobile__link nav-mobile__link--small text-small text-secondary d-flex align-center nav-mobile__to-animate"
        @click.native="$emit('close', $event)"
      >
        {{ $t('account.nav.orders') }}
      </nuxt-link>

      <nuxt-link
        v-if="authenticated && $i18n.locale === 'fr-FR'"
        :to="$contextPath('/account/parrainage')"
        class="nav-mobile__link nav-mobile__link--small text-small text-secondary d-flex align-center nav-mobile__to-animate"
        @click.native="$emit('close', $event)"
      >
        {{ $t('account.nav.parrainage') }}
      </nuxt-link>

      <nuxt-link
        v-if="authenticated"
        :to="$contextPath('/')"
        class="nav-mobile__link nav-mobile__link--small d-block text-small text-secondary mb-1 nav-mobile__to-animate"
        @click.native="logout"
      >
        {{ $t('account.nav.logout') }}
      </nuxt-link>

      <a
        href="#"
        class="nav-mobile__link d-block text-uppercase text-secondary wisp nav-mobile__to-animate"
      >
        <span>{{ $t('link.news') }}</span>
      </a>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import * as prismic from '@prismicio/client'
import { differenceInDays, differenceInHours, isAfter } from 'date-fns'

import I18nSwitcher from '@/components/i18n/Switcher'
import PrimaryButton from '@/components/buttons/Primary'

import { EVENTS } from '@/const/events'

export default {
  name: 'NavMobile',
  components: {
    PrimaryButton,
    I18nSwitcher,
  },
  props: {
    header: { type: Object, required: true },
  },
  data: () => ({
    renderTime: 0,
  }),
  computed: {
    ...mapGetters({
      authenticated: 'auth/authenticated',
    }),
    menu() {
      if (this.$route.params.context && this.$route.params.context === 'f') {
        return this.header.data.menu_f
      }

      return this.header.data.menu_h
    },
    eshopMenu() {
      if (this.$route.params.context && this.$route.params.context === 'f') {
        return this.header.data.shop_links_f
      }

      return this.header.data.shop_links_h
    },
    menuVersion() {
      return this.menu.filter(
        (link) =>
          link.ab_test_version === 'all' ||
          link.ab_test_version === process.env.SITE_VERSION
      )
    },
    eshopMenuVersion() {
      return this.eshopMenu.filter(
        (link) =>
          link.ab_test_version === 'all' ||
          link.ab_test_version === process.env.SITE_VERSION
      )
    },
    currentContext() {
      if (this.$route.params.context) {
        return this.$route.params.context
      }

      return 'h'
    },
  },
  mounted() {
    this.renderTime = new Date().getTime()

    document.addEventListener('visibilitychange', this.forceUpdate)
  },
  methods: {
    ...mapActions({
      disconnect: 'auth/disconnectCustomer',
    }),
    forceUpdate() {
      if (!document.hidden) {
        this.renderTime = new Date().getTime()
      }
    },

    hasSubmenu(link) {
      return prismic.isFilled.contentRelationship(link.sub_menu)
    },
    linkComponent(link) {
      return this.hasSubmenu(link)
        ? 'div'
        : prismic.isFilled.contentRelationship(link.link)
        ? 'nuxt-link'
        : 'a'
    },
    linkAttributes(link) {
      if (this.hasSubmenu(link)) {
        return {}
      }

      if (prismic.isFilled.contentRelationship(link.link)) {
        return {
          to: this.$contextPrismicPath(link.link),
          prefetch: this.$isAPrefetchLink(link.link),
        }
      }

      return {}
    },

    getCountdown(link) {
      if (link.countdown) {
        const date = new Date(link.countdown)
        const now = new Date()

        if (isAfter(date, now)) {
          const diffInDays = differenceInDays(date, now)

          if (diffInDays > 1) {
            return this.$t('countdown.abbr.days', {
              days: diffInDays,
            })
          }

          const diffInHours = differenceInHours(date, now)
          if (diffInHours >= 1) {
            return this.$t('countdown.abbr.hours', {
              hours: diffInHours,
            })
          }
        }
      }

      return ''
    },

    logout(ev) {
      this.disconnect().then(() => {
        this.$logError.removeIdentify()
        this.$nuxt.$emit(EVENTS.USER_LOGGED_OUT)
        this.$emit('close', ev)
      })
    },
  },
}
</script>

<style lang="scss">
.nav-mobile {
  width: rem(280px);
  right: rem(-280px);
  background: var(--secondary-color);
  color: var(--tertiary-color);
  display: flex;
  flex-direction: column;
  will-change: right;

  a {
    color: var(--tertiary-color);

    @include on-hover-and-focus {
      color: var(--tertiary-color);
    }
  }

  &.side-menu--open {
    right: 0;
  }

  hr {
    border: solid var(--secondary-color-1);
    border-width: 0 0 1px;
    margin: 0 var(--spacing);
  }

  &__eshop-links {
    hr {
      margin: var(--spacing);
    }
  }

  &__link {
    font-size: rem($h3-fs);
    line-height: 1.05;
    position: relative;
    padding: calc(var(--spacing) * 0.8) var(--spacing);

    svg {
      margin-right: calc(var(--spacing) * 0.5);
    }

    &.nuxt-link-exact-active {
      &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: rem(4px);
        background: var(--tertiary-color);
      }
    }

    .wisp_count {
      position: relative;
      top: rem(-12px) !important;
      right: 0 !important;
      display: inline-block;
      margin-left: rem(5px);
    }

    &--with-submenu {
      padding-bottom: 0;

      .nav-mobile__link {
        &::before {
          left: calc(var(--spacing) * -1);
        }
      }
    }

    &--highlight {
      --link-highlight-background: var(--yellow-color) default;
      --link-highlight-color: var(--secondary-color) default;

      background: var(--link-highlight-background);
      color: var(--link-highlight-color);

      &.nuxt-link-exact-active {
        &::before {
          background: var(--link-highlight-color);
        }
      }
    }

    &--context {
      position: relative;
      font-family: var(--secondary-font-family);
      font-size: rem($h6-fs);
      padding: var(--spacing);

      &:first-child {
        &::after {
          content: '';
          position: absolute;
          top: 50%;
          right: 0;
          width: rem(1px);
          height: rem(20px);
          background: var(--tertiary-color);
          transform: translateY(-50%);
        }
      }

      span {
        position: relative;
      }

      &.link-active {
        span {
          &::after {
            content: '';
            position: absolute;
            top: 100%;
            left: 0;
            right: 0;
            height: rem(1px);
            background: var(--tertiary-color);
            margin-top: calc(var(--spacing) * 0.2);
          }
        }
      }
    }
  }

  &__inner {
    padding-top: calc(var(--spacing) * 0.8);
    padding-bottom: calc(var(--spacing) * 0.8);
  }

  &__top {
    display: flex;
    justify-content: space-between;
    padding-left: var(--spacing) 0;
    position: relative;
    z-index: 1;

    a {
      display: flex;
      align-items: center;
      padding: var(--spacing);

      svg {
        width: rem(16px);
        height: rem(16px);
        margin-right: calc(var(--spacing) * 0.4);
      }
    }
  }

  &__bottom {
    padding: calc(var(--spacing) * 0.8) 0;

    .nav-mobile__link {
      font-size: rem($btn-primary-fs);
      line-height: 1;
      padding-top: calc(var(--spacing) * 0.8);
      padding-bottom: calc(var(--spacing) * 0.8);

      &--small {
        font-family: var(--primary-font-family);
        font-size: var(--small-font-size);
        padding-left: calc(var(--spacing) * 2);
      }
    }
  }

  .lang-switcher {
    text-align: right;
    font-size: rem($h5-fs);
    line-height: 1;

    &__link {
      a {
        padding: calc(var(--spacing) * 0.8) var(--spacing);
        padding-right: calc(var(--spacing) + #{rem(24px)});
      }

      .arrow {
        left: auto;
        right: rem(-24px);
      }
    }

    &__nav {
      margin-top: calc(var(--spacing) * -0.6);

      a {
        padding: calc(var(--spacing) * 0.4) var(--spacing);
        background: var(--secondary-color);
      }
    }
  }
}
</style>
